import { LazyExoticComponent, lazy } from 'react'
import { type TApplicationParams } from './Application'

const GameListApplication = lazy(() => import('./GameListApplication'))
const SearchModalApplication = lazy(() => import('./SearchModalApplication'))
const SearchResultsApplication = lazy(() => import('./SearchResultsApplication'))
const GameInfoApplication = lazy(() => import('./GameInfoApplication'))
const GameLaunchApplication = lazy(() => import('./GameLaunchApplication'))
const GameStudiosApplication = lazy(() => import('./GameStudiosApplication'))
const StudiosPageApplication = lazy(() => import('./StudiosPageApplication'))
const LikesCounterApplication = lazy(() => import('./LikesCounterApplication'))
const InGameJackpotBarApplication = lazy(() => import('./InGameJackpotBarApplication'))
const CasinoGamesApplication = lazy(() => import('./CasinoGamesApplication'))
const MkodoApplication = lazy(() => import('./MkodoApplication'))
const GameLaunchHandlerApplication = lazy(() => import('./GameLaunchHandlerApplication'))

type TLazyApplicationComponent = LazyExoticComponent<(props: TApplicationParams) => JSX.Element>
export const APPLICATION_COMPONENT_MAPPING: {
  [component in TApplicationParams['component']]: TLazyApplicationComponent
} = {
  list: GameListApplication as TLazyApplicationComponent,
  search: SearchModalApplication as TLazyApplicationComponent,
  'search-results': SearchResultsApplication,
  info: GameInfoApplication,
  launcher: GameLaunchApplication as TLazyApplicationComponent,
  studios: GameStudiosApplication as TLazyApplicationComponent,
  'studios-page': StudiosPageApplication,
  'likes-counter': LikesCounterApplication as TLazyApplicationComponent,
  'in-game-jackpot-bar': InGameJackpotBarApplication as TLazyApplicationComponent,
  'casino-games': CasinoGamesApplication,
  mkodo: MkodoApplication as TLazyApplicationComponent,
  'game-launch-handler': GameLaunchHandlerApplication
}
