import { decorateImportWithRetries } from '@casumo/fabric-fundamentals'
import { type ComponentType, lazy } from 'react'

// type `any` comes from React type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lazyWithRetries<T extends ComponentType<any>>(
  factory: Parameters<typeof lazy<T>>[0]
): ReturnType<typeof lazy<T>> {
  return lazy(decorateImportWithRetries(factory))
}
