import { TGameListType } from '../types'
import { CompactGridStyleVariant } from './CompactGridStyleVariant'
import { CompactStyleVariant } from './CompactStyleVariant'
import { DefaultGridStyleVariant } from './DefaultGridStyleVariant'
import { DefaultStyleVariant } from './DefaultStyleVariant'
import { DoubleWidthStyleVariant } from './DoubleWidthStyleVariant'
import { JackpotsStyleVariant } from './JackpotsStyleVariant'
import { LargeStyleVariant } from './LargeStyleVariant'
import { PromoStyleVariant } from './PromoStyleVariant'
import { SmallCompactGridStyleVariant } from './SmallCompactGridStyleVariant'
import { SmallCompactStyleVariant } from './SmallCompactStyleVariant'
import { SmallDefaultGridStyleVariant } from './SmallDefaultGridStyleVariant'
import { SmallDefaultStyleVariant } from './SmallDefaultStyleVariant'
import { type TStyleVariantComponent } from './types'

export const LIST_STYLE_COMPONENT_MAPPING: {
  [variant in TGameListType]: TStyleVariantComponent
} = {
  'small-compact': SmallCompactStyleVariant,
  'small-default': SmallDefaultStyleVariant,
  compact: CompactStyleVariant,
  default: DefaultStyleVariant,
  large: LargeStyleVariant,
  'double-width': DoubleWidthStyleVariant,
  jackpots: JackpotsStyleVariant,
  'small-compact-grid': SmallCompactGridStyleVariant,
  'small-default-grid': SmallDefaultGridStyleVariant,
  'compact-grid': CompactGridStyleVariant,
  'default-grid': DefaultGridStyleVariant,
  'hero-promo': PromoStyleVariant
}
