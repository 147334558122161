export const APPLICATION_NAME = 'fabric-game-list'
export const NAMESPACE = 'game-list'

/**
 * Device type for API calls
 */
export const DEVICES = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile'
}
export type AppDevice = (typeof DEVICES)[keyof typeof DEVICES]

/**
 * Poll interval for graphql queries
 */
export const POLL_INTERVAL = {
  GAMES_LIST: 1800000, // 30min
  JACKPOTS: 30000 // 30sec
}

export const GAMES_LIST_HORIZONTAL_ITEMS_LIMIT = 30

export const GAMEPLAY_MODES = {
  FUN: 'FUN',
  REAL: 'REAL'
}

export const ENVIRONMENTS = {
  TEST: 'test',
  PRODUCTION: 'production'
} as const
export type TEnvironment = ValueOf<typeof ENVIRONMENTS>

export const GAME_CATEGORIES = {
  SLOT_MACHINE: 'SLOT_MACHINE',
  LIVE_CASINO: 'LIVE_CASINO'
}

export const CURRENCIES = {
  EUR: 'EUR',
  GBP: 'GBP',
  NOK: 'NOK',
  SEK: 'SEK',
  CAD: 'CAD',
  NZD: 'NZD',
  INR: 'INR',
  USD: 'USD',
  DKK: 'DKK',
  JPY: 'JPY'
} as const

export type TCurrencyCode = ValueOf<typeof CURRENCIES>

/** Directus market codes */
export const MARKETS = {
  en: 'en',
  en_gb: 'en-gb',
  en_in: 'en-in',
  en_nz: 'en-nz',
  en_ca: 'en-ca',
  sv: 'sv',
  es: 'es',
  no: 'no',
  on: 'on',
  ie: 'ie',
  fi: 'fi',
  ja: 'ja',
  row: 'row',
  rog: 'rog'
} as const

/** Directus language codes */
export const LANGUAGES = {
  en: 'en',
  en_CA: 'en-CA',
  en_GB: 'en-GB',
  en_IE: 'en-IE',
  en_NZ: 'en-NZ',
  es_ES: 'es-ES',
  fi_FI: 'fi-FI',
  ja_JP: 'ja-JP',
  nb_NO: 'nb-NO',
  sv_SE: 'sv-SE'
} as const

export const API_MARKET = {
  en: '___en',
  ca: 'ca_en',
  fi: 'fi_fi',
  gb: 'gb_en',
  ie: 'ie_en',
  in: 'in_en',
  no: 'no_no',
  nz: 'nz_en',
  se: 'se_sv',
  es: 'es_es',
  jp: 'jp_ja',
  on: 'on_en'
} as const

export type TApiMarket = ValueOf<typeof API_MARKET>

export const API_MARKET_MAPPING: { [market: string]: TApiMarket } = {
  [MARKETS.en]: API_MARKET.en,
  [MARKETS.en_gb]: API_MARKET.gb,
  [MARKETS.en_in]: API_MARKET.in,
  [MARKETS.en_nz]: API_MARKET.nz,
  [MARKETS.en_ca]: API_MARKET.ca,
  [MARKETS.sv]: API_MARKET.se,
  [MARKETS.es]: API_MARKET.es,
  [MARKETS.no]: API_MARKET.no,
  [MARKETS.on]: API_MARKET.on,
  [MARKETS.ie]: API_MARKET.ie,
  [MARKETS.fi]: API_MARKET.fi,
  [MARKETS.ja]: API_MARKET.jp,
  [MARKETS.rog]: API_MARKET.en,
  [MARKETS.row]: API_MARKET.en
}

/**
 * @deprecated Wordpress CMS Country mapping
 */
export const CMS_COUNTRY: { [market: string]: string } = {
  [MARKETS.en]: 'en',
  [API_MARKET.en]: 'en',
  [MARKETS.en_gb]: 'gb',
  [API_MARKET.gb]: 'gb',
  [MARKETS.en_in]: 'in',
  [API_MARKET.in]: 'in',
  [MARKETS.en_nz]: 'nz',
  [API_MARKET.nz]: 'nz',
  [MARKETS.en_ca]: 'ca',
  [API_MARKET.ca]: 'ca',
  [MARKETS.sv]: 'sv',
  [API_MARKET.se]: 'sv',
  [MARKETS.es]: 'es',
  [API_MARKET.es]: 'es',
  [MARKETS.no]: 'no',
  [API_MARKET.no]: 'no',
  [MARKETS.on]: 'on',
  [API_MARKET.on]: 'on',
  [MARKETS.ie]: 'ie',
  [API_MARKET.ie]: 'ie',
  [MARKETS.fi]: 'fi',
  [API_MARKET.fi]: 'fi',
  [MARKETS.ja]: 'jp',
  [API_MARKET.jp]: 'jp',
  [MARKETS.rog]: 'rg',
  [MARKETS.row]: 'en'
}

export const ROUTE_IDS = {
  LOGIN: 'LOGIN',
  LOGIN_LOS: '/log-in',
  JACKPOT_EXPLAINER: 'JACKPOT_EXPLAINER',
  JACKPOTS_DETAILS: 'JACKPOTS_DETAILS',
  GAMES_SEARCH: 'GAMES_SEARCH',
  GAME_DETAILS: 'GAME_DETAILS',
  GAME_PROVIDER_GAMES: 'GAME_PROVIDER_GAMES',
  GAMES_TOP: 'GAMES_TOP',
  PLAY: 'PLAY',
  PRACTICE: 'PRACTICE',
  FAQ: 'FAQ',
  CASH_DEPOSIT: 'CASH_DEPOSIT'
} as const

export const MARKET_TO_CURRENCY_MAPPING: { [market: string]: TCurrencyCode } = {
  [MARKETS.en]: CURRENCIES.EUR,
  [MARKETS.en_gb]: CURRENCIES.GBP,
  [MARKETS.en_in]: CURRENCIES.INR,
  [MARKETS.en_nz]: CURRENCIES.NZD,
  [MARKETS.en_ca]: CURRENCIES.CAD,
  [MARKETS.sv]: CURRENCIES.SEK,
  [MARKETS.es]: CURRENCIES.EUR,
  [MARKETS.no]: CURRENCIES.NOK,
  [MARKETS.on]: CURRENCIES.CAD,
  [MARKETS.ie]: CURRENCIES.EUR,
  [MARKETS.fi]: CURRENCIES.EUR,
  [MARKETS.ja]: CURRENCIES.JPY,
  [MARKETS.row]: CURRENCIES.EUR,
  [MARKETS.rog]: CURRENCIES.EUR
}
