import cx from 'classnames'
import { type TStyleVariantProps } from './types'
import { HorizontalStyleVariant } from './HorizontalStyleVariant'

export const PromoStyleVariant = ({ className, children }: TStyleVariantProps) => (
  <HorizontalStyleVariant
    className={cx(
      '*:aspect-[1/1.185] flex-row-reverse',
      '*:w-[60.7%] 744:*:w-[30.46%] 1024:*:w-[16.89%] 1440:*:w-[19.05%] 1920:*:w-[15.94%]',
      className
    )}
  >
    {children}
  </HorizontalStyleVariant>
)
