import cx from 'classnames'
import { Link } from './Link'

type TLinkButton = {
  link: string
  showIfRouteNotFound?: boolean
  small?: boolean
  className?: string
  color?: 'purple-80' | 'white' | 'teal-50' | `sticky-yellow-100`
  onClick?: () => void
  children: React.ReactNode
}

export const LinkButton = ({
  link,
  showIfRouteNotFound,
  className,
  color = 'purple-80',
  small = false,
  onClick,
  children
}: TLinkButton) => {
  return (
    <Link
      link={link}
      onClick={onClick}
      showIfRouteNotFound={showIfRouteNotFound}
      className={cx(
        'inline-block',
        'click font-bold text-btn-sm px-xs',
        'rounded-full border-b-purple-100/30',
        color === 'white' && 'bg-white text-purple-80',
        color === 'teal-50' && 'bg-teal-50 text-purple-80',
        color === 'purple-80' && 'bg-purple-80 text-white',
        color === 'sticky-yellow-100' && 'bg-sticky-yellow-100 text-purple-80',
        small
          ? 'pt-[7px] pb-[6px] border-b-[6px]'
          : 'py-2xs border-b-[4px] 1024:py-xs 1024:border-b-[6px]',
        className
      )}
    >
      {children}
    </Link>
  )
}
