import { LazyExoticComponent } from 'react'
import { lazyWithRetries } from 'Utils/lazyWithRetry'
import type { TApplicationParams } from './Application'

const GameListApplication = lazyWithRetries(() => import('./GameListApplication'))
const SearchModalApplication = lazyWithRetries(() => import('./SearchModalApplication'))
const SearchResultsApplication = lazyWithRetries(() => import('./SearchResultsApplication'))
const GameInfoApplication = lazyWithRetries(() => import('./GameInfoApplication'))
const GameLaunchApplication = lazyWithRetries(() => import('./GameLaunchApplication'))
const GameStudiosApplication = lazyWithRetries(() => import('./GameStudiosApplication'))
const StudiosPageApplication = lazyWithRetries(() => import('./StudiosPageApplication'))
const LikesCounterApplication = lazyWithRetries(() => import('./LikesCounterApplication'))
const InGameJackpotBarApplication = lazyWithRetries(() => import('./InGameJackpotBarApplication'))
const CasinoGamesApplication = lazyWithRetries(() => import('./CasinoGamesApplication'))
const MkodoApplication = lazyWithRetries(() => import('./MkodoApplication'))
const GameLaunchHandlerApplication = lazyWithRetries(() => import('./GameLaunchHandlerApplication'))

type TLazyApplicationComponent = LazyExoticComponent<(props: TApplicationParams) => JSX.Element>
export const APPLICATION_COMPONENT_MAPPING: {
  [component in TApplicationParams['component']]: TLazyApplicationComponent
} = {
  list: GameListApplication as TLazyApplicationComponent,
  search: SearchModalApplication as TLazyApplicationComponent,
  'search-results': SearchResultsApplication,
  info: GameInfoApplication,
  launcher: GameLaunchApplication as TLazyApplicationComponent,
  studios: GameStudiosApplication as TLazyApplicationComponent,
  'studios-page': StudiosPageApplication,
  'likes-counter': LikesCounterApplication as TLazyApplicationComponent,
  'in-game-jackpot-bar': InGameJackpotBarApplication as TLazyApplicationComponent,
  'casino-games': CasinoGamesApplication,
  mkodo: MkodoApplication as TLazyApplicationComponent,
  'game-launch-handler': GameLaunchHandlerApplication
}
